"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var plugins = {};
var Plugins = {
    register: function (name, constructor) {
        plugins[name] = constructor;
    },
    get: function (name) {
        if (typeof plugins[name] === "function") {
            return plugins[name];
        }
        return null;
    },
    all: function () {
        return plugins;
    }
};
window.Plugins = Plugins;
exports.default = Plugins;
